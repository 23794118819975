h1 {
    margin-top: 0.1em;
    margin-bottom: 0.2em;
}

h2 {
    margin-bottom: 1rem;
}

.ic {
    @extend %fa-icon;
    @extend .fas;
}

.ic-home {
    content: fa-content($fa-var-home);
}

a.external {
    &:after {
        @extend .fas;
        content: "\f35d"; //$fa-var-external-link-alt;
        font-size: 0.8em;
        margin-left: 0.4em;
}
}