.cns-grid-container {
    @extend .container;
}

.cns-grid-header {
    @include make-row();
}

.cns-grid-item {
    @include make-row();
    justify-content: center !important;

    h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.cns-grid-item-picto {
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.3em;
    img {
        max-height: 1.8em;
        vertical-align: middle;
    }
}

.cns-grid-filter {
    @extend .form-inline;
    margin-bottom: 1.2em;
    position: relative;
    margin-left: 0.6em;
    padding-bottom: 0.4em;
    justify-content: flex-end;

    .form-group {
        margin-left: 0.5em;
    }
    .icon {
        position: relative;
        left: 2.3em;
    }

    .form-control {
        display: inline-block;
        height: 2.5em;
        padding-left: 2.6em;
    }
}

#cns-grid-empty-item,
#cns-grid-empty-filtered-item {
    @include make-row();
    justify-content: center !important;
    margin-top: 3em;
    margin-bottom: 3em;
}

#cns-grid-empty-filtered-item {
    display: none;
}