form#cns-filters {
    @extend .form-inline;
    margin-bottom: 8px;
    display: inline-block;
    @include media-breakpoint-up(lg) {
        text-align: right;
        margin-top: 12px;
    }
}

table.table {
    span.time, span.percent, span.integer, span.euro {
        text-align: right;
        display: block;
        margin-right: 1em;
    }

    span.datetime {
        white-space: nowrap;
    }

    span.check-true {
        display: block;
        text-align: center;
        color: green;
    }

    span.check-false {
        display: block;
        text-align: center;
        color: map-get($theme-colors, 'primary');
    }

    span.https-value {
        display: block;
        text-align: center;
    }

    tr.filters td {
        padding: 2px;
        background: #f3f3f3;

        input, select {
            width: 100%;
            border: none;
            padding: 1px;
            font-style: italic;
        }
    }

    td.actions {
        padding-top: 4px;
        padding-bottom: 4px;
        text-align: right;

        div.actions a {
            padding-top: 3px;
            padding-bottom: 3px;
        }
    }

    td.pre {
        white-space: pre;
    }
}

.dataTables_info {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    width: 49%;
}

div.dataTables_paginate {
    display: inline-block;
    text-align: right;
    vertical-align: middle;
    width: 80%;

    .pagination {
        margin: 0;

        & > li > a {
            border: 0;
        }
        & > li:last-child > a,
        & > li:last-child > span {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }
    }
}


div.dt-buttons {
    float: right;
    margin-bottom: 6px;
    margin-top: 6px;
    .btn {
        @extend .btn-sm;
    }
}