.cns-block {
    @extend .card;
    margin-bottom: 1.3rem;

    &.disabled {
        opacity: 0.3;
    }
}

.cns-block-body {
    @extend .card-body;
}

.cns-prop {
    margin-bottom: ($spacer * .5) !important;
    .label {
        display: inline-block;
        vertical-align: top;
        font-weight: bold;
        &:after {
            content: ' : '
        }
    }
    .value {
        margin-left: ($spacer * .5) !important;
        vertical-align: top;
        display: inline-block;
    }
    .value-block {
        margin-left: ($spacer * 4) !important;
        display: block;
    }

    .value-item + .value-item {
        margin-top: ($spacer * .25) !important;
    }

    form.form-inline{
        display: inline;
    }

    .progress {
        height: 0.5rem;
    }
}
