.help-block {
    position: relative;
    font-size: 0.9em;
    display: inline-block;
    border-left: 2px solid lighten(map-get($theme-colors, 'primary'), 20%);
    padding-left: 0.4em;
    margin-left: 2.2em;
    p {
        margin-bottom: 0.3em;
    }
    &:before {
        @include fa-icon;
        @extend .fas;
        content: fa-content($fa-var-question-circle);
        color: lighten(map-get($theme-colors, 'primary'), 20%);
        position: absolute;
        top: 0.4em;
        left: -1.4em;
        font-size: 1.2em;
        font-weight: bold;
    }
}

ul.checkbox-list-control {
    @extend .form-control;
    height: auto;
    overflow-y: auto;
    list-style: none;
    max-height: 13em;
    padding-top: 0;
}

textarea.form-code {
    font-family: $font-family-monospace;
    white-space: nowrap;
}