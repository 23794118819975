.page-filters {
    @extend .form-inline;
    margin-bottom: 1.2em;
    position: relative;
    margin-left: 0.6em;
    padding-bottom: 0.4em;
    justify-content: flex-end;

    .form-group {
        margin-left: 0.5em;
    }
    // .icon {
    //     position: relative;
    //     left: 2.3em;
    // }

    // .form-control {
    //     display: inline-block;
    //     height: 2.5em;
    //     padding-left: 2.6em;
    // }

    .pf-apply {
        visibility: hidden;
    }

    &.changed {
        background: #fbebb0;
        border: #f3c370 1px solid;
        .pf-apply {
            visibility: visible;
        }
    }
}

.pf-dayrange {
    @extend .form-control;
    cursor: pointer;

    i {
        @extend .fa;
        @extend .fa-calendar;
        margin-left: 0.5rem;
    }

    min-width: 17rem;
}
