body {
    background: white;
    overflow-y: scroll;
}

body.body-grayed {
    background: map-get($theme-colors, 'background');
}

.cns-header {
    @extend .container-fluid;
}

h1 small {
    display: block;
    font-size: 0.6em;
}