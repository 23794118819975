@import "variables";

// Required
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// Optional
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/utilities";

@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/badge";
@import "node_modules/bootstrap/scss/breadcrumb";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/custom-forms";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/input-group";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/pagination";
@import "node_modules/bootstrap/scss/popover";
@import "node_modules/bootstrap/scss/progress";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/transitions";

// Icons
// @import 'node_modules/material-icons/iconfont/material-icons.scss';
// @import 'node_modules/material-icons/css/material-icons.scss';
@import 'node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import 'node_modules/@fortawesome/fontawesome-free/scss/solid.scss';
@import 'node_modules/@fortawesome/fontawesome-free/scss/regular.scss';


// Components
@import "node_modules/bootstrap/scss/breadcrumb";

@import "block";
@import "form";
@import "global-layout";
@import "grid";
@import "table";
@import "text";
@import "timesheet";
@import "page-filters";

// Login
.cns-page-login .cns-logo {
    margin-top: 4em;
    margin-bottom: 3em;
}

.empty-table {
    text-align: center;
    td {
        padding-top: 1.2em;
        padding-bottom: 1.2em;
        font-style: italic;
    }
}

span.code {
    font-family: $font-family-monospace;
    background: map-get($theme-colors, 'light');
    white-space: nowrap;
    font-size: 0.9em;
    padding: 0.1em 0.4em;
}

.staging-url {
    font-size: 0.9em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.staging-screenshot {
    @extend .img-fluid;
    border: 1px solid #ccc;
}

.scp-spinner-layer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    left: 0.2em;
    right: 0.2em;
    top: 0.2em;
    bottom: 0.2em;
    background: rgba(255, 255, 255, 0.9);
    z-index: 99999;
    i {
        font-size: 1.5em;
        color: map-get($theme-colors, 'primary');
    }
    span {
        display: block;
        font-weight: 0.9em;
        font-style: italic;
    }
}

.scp-alert {
    @extend .alert;
    position: relative;
    padding-left: 3.7rem;
}

.scp-alert-icon {
    position: absolute;
    left: 1.25rem;
    font-size: 1.6rem;
}

.scp-commit {
    @include make-row();
    .scp-reference {
        @include make-col-ready();
        @include make-col(6);
    }
    .scp-date {
        @include make-col-ready();
        @include make-col(6);
            text-align: right;
    }
    .scp-comment {
        @include make-col-ready();
        @include make-col(12);
        div {
            text-align: right;
        }
    }
    pre {
        margin-bottom: 0;
        padding: 0.2em;
        background: #eee;
    }
}

.scp-staging-history {
    margin-left: 3em;
    margin-right: 3em;

    .row:nth-child(odd) {
        background-color: #e3e3e3;
    }
    .row:nth-child(even) {
        background-color: #fbfbfb;
    }
}


.datepicker {
    // @extend .has-feedback;
    cursor: pointer;

    span {
        @extend .form-control;
        cursor: inherit;
    }

    i {
        // @extend .glyphicon;
        // @extend .glyphicon-calendar;
        // @extend .form-control-feedback;
    }
}

