
.scp-time-weekday {
    display: block;
    text-align: center;
}

.scp-time-date {
    display: block;
    font-size: 0.8em;
    font-weight: normal;
    text-align: center;
}

.scp-time-activity {
    width: 10%;
}

.scp-time-comments {
    width: 13%;
}

#scp-time-copy-dialog {
    .modal-dialog {
        max-width: 80vw;
    }
    textarea {
        font-family: monospace;
        font-size: 0.8em;
        white-space: nowrap;
        width: 100%;
        height: 70vh;
    }
}
